
<template>
  <div id="page-user-view">

    <vs-alert color="danger" title="User Not Found" :active.sync="user_not_found">
      <span>User record with id: {{ $route.params.userId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{path:'/users'}" class="text-inherit underline">All Users</router-link>
      </span>
    </vs-alert>

    <div id="user-data" v-if="user.id">
      <vx-card class="mb-base">
        <div class="vx-row pt-2">
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="$store.state.domain+'/'+user.image" class="rounded w-full" />
            </div>
          </div>
          <div class="vx-col flex-1" id="account-info-col-1">
            <table>
              <tr>
                <td class="font-semibold">Name</td>
                <td>{{ user.first_name }} {{ user.last_name }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Username</td>
                <td>{{ user.username }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Email</td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Role/Status</td>
                <td>{{ user.role }} / {{ user.status }}</td>
              </tr>
            </table>
          </div>
          <div class="vx-col flex-1" id="account-info-col-2">
            <table>
              <tr>
                <td class="font-semibold">Country</td>
                <td><span class="flex items-center" v-if="user.country">
                  <img class="w-8 h-4 mr-1" :src="$store.state.domain+'/images/country_flags/'+user.country.icon" alt="star" />
                  {{user.country.name}}</span></td>
              </tr>
              <tr>
                <td class="font-semibold">Verification</td>
                <td>{{ user.is_verified ? 'Verified':"Not verified" }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile</td>
                <td>{{ user.mobile }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Gender</td>
                <td>{{ user.gender }}</td>
              </tr>
            </table>
          </div>
          <div class="vx-col w-full flex" id="account-manage-buttons">
            <vs-button icon-pack="feather" size="small" icon="icon-edit" class="mr-4" :to="`/users/${user.id}/edit`">Edit</vs-button>
            <vs-button v-if="canDelete" @click="confirmDeleteRecord()" type="border" color="danger" size="small" icon-pack="feather" icon="icon-trash" >Delete</vs-button>
          </div>

        </div>
      </vx-card>

      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Information" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Birth Date</td>
                <td>{{ user.dob }}</td>
              </tr>
              <tr>
                <td class="font-semibold">skills</td>
                <td>{{ parseArray(user.skills) }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Languages</td>
                <td>{{ parseArray(user.languages) }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Bio</td>
                <td>{{ user.about }}</td>
              </tr>
            </table>
          </vx-card>
        </div>

        <div class="vx-col lg:w-1/2 w-full">
          <vx-card title="Social Links" class="mb-base">
            <table>
              <tr>
                <td class="font-semibold">Twitter</td>
                <td>{{ user.twitter }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Facebook</td>
                <td>{{ user.facebook }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Instagram</td>
                <td>{{ user.instagram }}</td>
              </tr>
              <tr>
                <td class="font-semibold">Github</td>
                <td>{{ user.github }}</td>
              </tr>
            </table>
          </vx-card>
        </div>
      </div>
      <vx-card v-if="canShowOrder && (isUserBuyer || isUserWorker)">
        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="ShoppingCartIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Orders</span>
            </div>
            <vs-divider />
             <order-list-table :orderList="orderList" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import OrderListTable from "@/views/orders/components/OrderListTable.vue"

export default {
  components:{
    OrderListTable
  },
  data() {
    return {
      user_not_found: false,
    }
  },
  watch: {
    "$route":"fetchUser"
  },
  computed: {
    user(){
      return this.$store.state.userList.user
    },
    canDelete(){
      return this.$route.params.userId != this.$store.state.AppActiveUser.id
    },
    canShowOrder(){
      return (this.user.id !== this.$store.state.AppActiveUser.id)
    },
    isUserBuyer(){
      return this.user.role == "buyer"
    },
    isUserWorker(){
      return this.user.role == "worker"
    },
    orderList(){
      return this.isUserBuyer
        ? this.$store.getters["orderList/getBuyerOrders"](this.user.id)
        : this.isUserWorker
          ? this.$store.state.userList.workerOrders
          : []
    }
  },
  methods: {
    parseArray(arrayObj){
        if(arrayObj.length < 1) {return}
        let trArray = []
        arrayObj.forEach(item => trArray.push(item.name))
        return trArray.join(", ")
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Delete`,
        text: `You are about to delete "${this.user.first_name}"`,
        accept: this.deleteRecord,
        acceptText: "Delete"
      })
    },
    deleteRecord() {
      if(this.user.id < 0 ) {return}
      this.$store.dispatch("userList/deleteUser", this.user.id)
      .then(response => {
        if(response.data.success){
          this.$router.push({path:'/users'});
          this.alertSuccess(response.data.success)
        }else{
          this.alertError(response.data.error)
        }
      })
      .catch(error => { console.log(error) })
    },
    fetchUser(){
      this.$vs.loading({scale: 0.6});
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
        .then(response => {
          this.$vs.loading.close()
          if(response.data.success){
            this.$store.commit("userList/SET_USER", response.data.user)
            if(response.data.orders){
              this.$store.commit('userList/SET_WORKER_ORDERS', response.data.orders)
            }
          }else{
            this.user_not_found = true
            this.alertError(response.data.error)
          }
        }).catch(error => {
            this.$vs.loading.close()
            this.user_not_found = true
            console.log(error)
        })
    }
  },
  created() {
    if(this.$route.params.userId) {
      this.fetchUser();
    }
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
